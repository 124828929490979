<template>
  <b-card-code
    title="Actualizar Marca"
  >
    <b-card-body>
      <FormCreateComponent
        :id="id"
        root="brands"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BCardBody } from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'

export default {
  name: 'BrandEditPage',
  components: {
    BCardCode,
    BCardBody,
    FormCreateComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id() {
    },
  },
}
</script>
